@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./mixins/media.scss";
@import "./mixins/fonts.scss";
@import "./fonts/typography.scss";

:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-dark: #141413;
  --color-beige: #f8f0e2;
  --color-light-beige: #fcf9f3;
  --color-link: #327cc6;
  --color-light: rgba(221, 214, 201, 0.1);
  --color-grey: #949191;
  --color-placeholder: rgba(0, 0, 0, 0.45);
  --color-border: rgba(0, 0, 0, 0.5);
}

html {
  height: 100vh;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "FuturaPT", sans-serif;
  font-size: 16px;

  color: var(--color-dark);
}

.ReactModal__Body--open {
  overflow: hidden;
}

p {
  @include font-paragraph;
  @include font-medium;
  white-space: pre-line;
}

a {
  color: inherit;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

svg {
  display: inline-block;
}

.page {
  padding-top: 1rem;
}

.container {
  max-width: 100%;
  @include desktop {
    max-width: 77.5rem;
    margin: auto;
    // padding: rem(16);
  }
}

.text-link {
  color: var(--color-link);
}

.LightboxPortal {
  .ReactModal__Content {
    max-width: -webkit-fill-available;
    max-height: -webkit-fill-available;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 0;

    @include desktop {
      max-width: 90vw;
      max-height: 98vh;
      width: auto;
      height: auto;
    }
  }
}

.form-group-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: rem(6);
}
