@import "../../styles/mixins.scss";

.Button {
  --fg-color: white;
  --bg-color: var(--color-dark);

  border: 1px solid var(--color-border);
  background: var(--bg-color);
  color: var(--fg-color);
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  display: inline-block;
  width: fit-content;

  &.Dark {
    --fg-color: var(--color-dark);
    --bg-color: white;
  }

  &.Rounded {
    border-radius: 2rem;
  }

  &.Outline {
    background: transparent;
    color: var(--bg-color);
    transition: all 150ms linear;
    border-color: var(--bg-color);

    &:active,
    &:hover {
      color: var(--fg-color);
      background: var(--bg-color);
    }
  }

  &.Small {
    @include font-button-small;
    padding: 0.2rem 0.8rem;
  }

  &.Mini {
    @include font-button-small;
    padding: rem(2) rem(12);
  }

  &.Clear {
    border: 0;
    background: none;

    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }

  &.Block {
    display: block;
    width: 100%;
  }
}
