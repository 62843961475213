@import "../../styles/mixins.scss";

.CloseButton {
  position: fixed;
  z-index: 10;
  top: 1rem;
  right: 1rem;

  @include desktop {
    top: 2.25rem;
    right: 2.25rem;
  }

  &Lightbox {
    top: 0.2rem;
    right: 0.2rem;

    @include desktop {
      top: 0.2rem;
      right: 0.2rem;
    }
  }
}

//  See global.scss for LightboxPortal settings.
// This is to provide styling to the ReactModalPortal that is created.
// .LightboxPortal {
// }
