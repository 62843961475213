@import "../../styles/mixins.scss";

.AnimatedForm {
  display: none;

  @include desktop {
    display: block;
    position: fixed;
    top: 0;
    right: max(calc((100vw - rem(1250)) / 2), rem(16));
    transform: translateY(-100%);
    transition: transform 150ms linear;
    z-index: 10;
    background: white;
    border: 1px solid var(--color-border);
  }

  &.Open {
    transform: translateY(rem(57));
  }

  .CloseButton {
    position: absolute;
    top: rem(18);
    right: rem(12);
  }
}
