@import "../../styles/mixins.scss";

.FooterWrapper {
  margin-top: rem(231);
}

.Footer {
  background: var(--color-dark);
  padding: 2rem 2rem;
  color: white;

  @include desktop {
    padding: 8rem 8rem 1rem 8rem;
  }
}

.Menus {
  @include desktop {
    display: flex;
    flex-direction: row;
  }
}

.SiteMenu {
  @include desktop {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.Menu {
  min-width: 5rem;

  @include desktop {
    margin: 0 4rem 0 0;
  }

  h3 {
    @include font-normal;
    @include font-heavy;
    margin: rem(45) 0 rem(24) 0;

    @include desktop {
      margin-top: 0;
      margin-bottom: rem(35);
    }
  }

  ul {
    li {
      @include font-book;
      @include font-mini;
      line-height: 1.375rem;
      margin: rem(16) 0;
      @include desktop {
        margin: rem(16) 0;
      }

      a {
        @include animated-underline(1px);
      }
    }
  }
}

.SocialMenu {
  text-align: left;
  flex: 1;

  @include desktop {
    text-align: right;
  }

  h3 {
    @include font-normal;
    @include font-heavy;

    margin: 3rem 0 2rem 0;

    @include desktop {
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }

  ul {
    margin: 2rem 0;
    li {
      a {
        @include font-xlarge;
        @include font-bold;
        @include animate-left-bump(500ms);
        line-height: 2rem;
        text-transform: uppercase;
      }
    }
  }
}

.Legal {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 9rem;
  }

  p {
    @include font-book;
    @include font-mini;
    margin: 1rem 0;

    a {
      text-decoration: underline;
    }
  }
}
