@import "../../styles/mixins.scss";

.NewsletterFormSection {
  background: var(--color-beige);
}

.NewsletterFormMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;

  @include desktop {
    display: none;
    padding: 0.5rem 0;
  }
}

.NewsletterFormDesktop {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;

  @include desktop {
    display: flex;
    padding: 0.5rem 0;
  }
}

.Label {
  font-size: 0.9rem;
  flex: 1;

  @include desktop {
    font-size: 1rem;
  }
}

.FormGroup {
  border-bottom: 1px solid var(--color-border);
  flex: 1;
  padding-bottom: 0.2rem;

  form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  input {
    background: none;
    font-size: 0.9rem;
    width: 60%;
    padding: 0.2rem;
    &::placeholder {
      color: var(--color-dark);
      opacity: 1;
    }

    @include desktop {
      width: 100%;
      outline: none;
      font-size: 1rem;
    }
  }

  .Button {
    font-size: 0.9rem;
    padding: 0;

    @include desktop {
      font-size: 1rem;
      @include animate-right-bump(500ms);
    }
  }
}
