@mixin animated-underline($height, $speed: 0ms) {
  display: inline-block;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: $height;
    bottom: 3px;
    background: currentColor;
    transform-origin: center right;
    transition: transform $speed cubic-bezier(0.55, 0, 0.1, 1);
    transform: scaleX(0);
  }

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: center left;
    }
  }
}

@mixin animate-left-bump($speed: 0ms) {
  transition: all $speed;

  &:hover {
    padding-right: rem(16);
  }
}

@mixin animate-right-bump($speed: 0ms) {
  transition: all $speed;

  &:hover {
    transform: translateX(rem(16));
  }
}
