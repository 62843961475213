@import "../../styles/mixins.scss";

.FormGroup {
  width: 100%;
  margin: rem(6) 0;

  &.Inline {
    margin: 0;
  }
}

.Select {
  @include font-book;
  @include font-normal;
  border: 1px solid var(--color-grey);
  background: white;
  border-radius: 0;
  padding: rem(4) rem(16);
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  min-height: rem(36);

  &:focus {
    outline: none;
  }

  &.Empty {
    @include font-book;
    @include font-normal;
    color: var(--color-placeholder);
    font-weight: 300;
  }

  & > option {
    &[disabled] {
      color: var(--color-placeholder);
    }
    color: black;
    @include font-book;
  }
}

.IconArrow {
  position: absolute;
  right: rem(16);
  top: rem(16);
  pointer-events: none;
}
