@import "../../styles/mixins.scss";

.Title {
  @include font-bold;
  font-size: rem(28);
  width: rem(351);
  margin: 0 auto;
  text-align: center;
}

.Message {
  @include font-book;
  @include font-mini;
  text-align: center;
  line-height: rem(18);
  margin: rem(4) 0;
}
