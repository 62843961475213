@import "../../styles/mixins.scss";

.MobileNav {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: white;
  z-index: 10;

  @include desktop {
    display: none;
  }

  .MobileLogo {
    display: block;
  }
}

.MobileMenu {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-white);
  z-index: 5;

  padding: 4rem 1rem;

  @include desktop {
    display: none;
  }

  ul {
    li {
      display: block;
      margin: rem(24) 0;
      a {
        @include font-demi;
        font-size: rem(26);
      }
    }
  }

  .Contact {
    margin-top: rem(65);
    p {
      @include font-book;
      @include font-normal;
    }
    a {
      color: var(--color-link);
    }
  }
}

.MenuEnter {
  opacity: 0;
  transform: scale(0.9);
}

.MenuEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.MenuExit {
  opacity: 1;
}

.MenuExitActive {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.MobileToggle {
  cursor: pointer;
  position: absolute;
  top: rem(16);
  left: 1rem;
  width: 1rem;
  height: 1rem;

  svg {
    width: auto;
    height: auto;
  }
}

.MobileRightNav {
  @include font-book;
  position: absolute;
  right: rem(16);
  top: rem(18);
  font-size: rem(15);

  @include desktop {
    display: none;
  }
}

.DesktopNav {
  display: none;
  position: fixed;
  z-index: 10;
  width: 100%;
  background-color: var(--color-white);

  @include desktop {
    display: block;
  }
}

.DesktopWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: rem(16) 0;
}

.DesktopLeftNav {
  position: absolute;
  left: 0;
  top: rem(16);
  a {
    @include animated-underline(1px);
    margin-right: 2rem;
  }
}

.DesktopRightNav {
  position: absolute;
  right: 0;
  top: rem(16);
  a {
    @include animated-underline(1px);
    margin-left: 2rem;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    .UserIcon {
      margin-top: rem(-2);
    }
  }
}
