@import "../../styles/mixins.scss";

.FormGroup {
  width: 100%;
  margin: rem(6) 0;

  &.Inline {
    margin: 0;
  }
}

.Input {
  @include font-book;
  @include font-normal;
  border: 1px solid var(--color-grey);
  border-radius: 0;
  padding: 0.5rem 1rem;
  width: 100%;

  &:focus {
    outline: none;
  }
}
