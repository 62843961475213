@font-face {
  font-family: "FuturaPT";
  src: url("/fonts/FuturaPTLight.otf") format("opentype");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "FuturaPT";
  src: url("/fonts/FuturaPTBook.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "FuturaPT";
  src: url("/fonts/FuturaPTMedium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "FuturaPT";
  src: url("/fonts/FuturaPTDemi.otf") format("opentype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "FuturaPT";
  src: url("/fonts/FuturaPTBold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "FuturaPT";
  src: url("/fonts/FuturaPTHeavy.otf") format("opentype");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "FuturaPT";
  src: url("/fonts/FuturaPTExtraBold.otf") format("opentype");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "EastroomWorkspaces";
  src: url("/fonts/EastroomWorkspaces.otf") format("opentype");
  font-weight: 400;
  font-display: block;
}
