@import "../../styles/mixins.scss";

:root {
}

.datePicker {
  --rdp-accent-color: var(--color-dark);
  --rdp-background-color: var(--color-beige);
  --rdp-accent-color-dark: var(--color-dark);
  --rdp-background-color-dark: var(--color-beige);
  --rdp-outline: 2px solid var(--color-dark);
  --rdp-outline-selected: 2px solid var(--color-dark);

  z-index: 100;
  position: absolute;
  background-color: var(--color-white);
  border: rem(1) solid var(--color-grey);
  box-sizing: border-box;
  box-shadow: 0 0 rem(60) rgba(65, 75, 85, 0.15);
  padding: rem(24);
}

.DateInput {
  width: 100%;
  flex: 1;
}

.IconArrow {
  position: absolute;
  right: rem(16);
  top: rem(16);
  pointer-events: none;
}
